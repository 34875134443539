.rdt_TableHeadRow {
  white-space: pre-line !important;
  overflow: inherit !important;
  text-align: center !important;
}
.custom-hover:hover {
  cursor: pointer;
}
.rdt_TableCol .rdt_TableCol_Sortable div {
  white-space: pre-line !important;
}

/* .rdt_Table { margin-bottom: 150px;} */
.output {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}
.shopWidth {
  width: 800px !important;
}
.thumbnail {
  height: 200px;
}
/* .onlyCapitalized{ text-transform: capitalize !important;} */

/* Font Selection  */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=""]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=""]::before {
  content: "Default";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="poppins"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="poppins"]::before {
  content: "poppins";
  font-family: "Poppins";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="latin"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="latin"]::before {
  content: "latin";
  font-family: "latin";
}

/* Font Size Class */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26";
  font-size: 26px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
  font-size: 28px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30";
  font-size: 30px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32";
  font-size: 32px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
  content: "40";
  font-size: 40px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="45x"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="45px"]::before {
  content: "45";
  font-size: 45px !important;
}

.orders-table {
  z-index: 0;
  position: relative;
}
.search-option {
  z-index: 99999;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #ededed;
  border-radius: 5px;
  position: absolute;
  background: #fff;
}
.search-option ul {
  list-style: none;
  padding: 6px;
}
.search-option ul li {
  list-style: none;
  font-size: 14px;
  padding-bottom: 8px;
}
.search-option ul li:hover {
  cursor: pointer;
}
.invoice-print .invoice-total-wrapper { max-width: 16rem !important;}
